.card-content-live{
    background: rgba(0, 0, 0, 0.25);
}
a {
  text-decoration: none; /* Remove the underline */
  /* Additional styling for links can be added here, such as color */
}



.card-box-live .image-box.is-loading,
.card-box-live.is-loading .video-spec,
.card-box-live.is-loading .sub-title{
	 background: #eee;
	 background: linear-gradient(110deg, #ececec 8%, #f5f5f5 18%, #ececec 33%);
	 border-radius: 5px;
	 background-size: 200% 100%;
	 animation: 1.5s shine linear infinite;
}

.card-box-live.is-loading img{
    display: none;
}


 @keyframes shine {
	 to {
		 background-position-x: -200%;
	}
}