.parallax {
  /* The image used */  /* Set a specific height */
  min-height: 500px;

  /* Create the parallax scrolling effect */
  background-attachment: fixed;
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
}

.indicator-done{
  color: white;
}

.indicator-done::after{
   display: block;
    content: "";
    position: absolute;
    width: 2px;
    background-color: #54989C;
    height: 70px;
    top: 75px;
    z-index: -2;

}

.indicator::after{
    display: block;
    content: "";
    position: absolute;
    width: 2px;
    background-color: orange;
    height: 70px;
    top: 75px;
    z-index: -2;
}

.last-element::after {
    display: none;
    content: '';
    height: 0px;
}

.image-detail{
  object-position: 50% 30%;
}

