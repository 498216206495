.circle-image-container.is-loading {
	 background: #eee;
	 background: linear-gradient(110deg, #bbbaba 8%, #908f8f 18%, #ececec 33%);
	 background-size: 200% 100%;
	 animation: 1.5s shine linear infinite;
}

.circle-image-container{
    width: 56px;
    height: 56px;
    border-radius: 50%;
}

.circle-image-container.is-loading img{
     opacity: 0;
}
.circle-image-container img{
     opacity: 1;
}

 @keyframes shine {
	 to {
		 background-position-x: -200%;
	}
}