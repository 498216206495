#lista-spotify *{
    background-color:#DD7C00 !important ;
}

.social-media-section p{
    font-family: 'DIN-Condensed';
    font-size: 1.3rem;
    line-height: 1.2rem;
}

#social-icons{
    display: flex;
    flex-direction: row;
    gap:0.75rem;
}

#social-icons .icon-img{
    height: 42px;
    
}

.social-media-section #social-icons .icon-img img{
    object-fit: contain;
    height: 100%;
    width: 100%;
    filter: brightness(0) saturate(100%) invert(92%) sepia(16%) saturate(287%) hue-rotate(12deg) brightness(98%) contrast(91%);

}