.subscription-items-section{
    gap: 0;
}

.susbcription-september{
      /*background: linear-gradient(180deg, #f5c147 0%, #FCCE92 20%, #FFFFFF 80%);*/
       background: linear-gradient(180deg, #EAE5C8 0%, #FFFFFF 70%);
     padding-bottom: 5rem;
     justify-content: center;
     overflow: hidden;
     display: flex;
     flex-wrap: wrap;
}
.susbcription-september .content{
    z-index: 0;
}
.rating-section{
     background: linear-gradient(180deg, #FFFFFF 0%, #FFFFFF 30%, #EAE5C8 100%);

}

.rating-boxes{
    z-index: 10;
}

.rating-section .mandala-box{
    top: 2800px;
}

.susbcription-september .image-container-nb{
    height: 90vh;
    width: 50%;
    border-radius: 0;
    overflow: hidden;
}

.susbcription-september .image-container-nb img{
    object-fit: cover;
    object-position: center;
    height: 100%;
    width: 100%;
}

.susbcription-september .content{
    width: 50%;
    gap: 3rem;
}
.susbcription-september .p-content{
    padding: 2rem 3.5rem;
    text-align: center;
}
@media screen and (max-width:600px){

    .mandala-image{
        display: none;
    }

.susbcription-september .image-container-nb{
    width: 100%;
    height: 350px;
    border-radius: 0;
    overflow: hidden;
}

.susbcription-september .image-container-nb img{
    object-position: 50% 61%;
}

.susbcription-september .content{
    width: 100%;
    padding: 1rem;
    gap: 3rem;
}

.susbcription-september .p-content{
    padding: 1rem;
}
    .subscription-items-section{
        gap:2rem
    }

    .mi-bio-section .text-content{
        padding-left:1rem;
        text-align: center;
    }

    .rating-section .mandala-box{
        display: none;
    }

    .rating-section{
        padding: 0.5rem;
    }

    .rating-section h2{
        padding: 1rem;
    }
    
}