.chat_box {
	position: relative;
	max-width: 45em;
	background-color: #fff;
	padding: 1.125em 1.5em;
	font-size: 1em;
	border-radius: 1rem;
    box-shadow:	0 0.125rem 0.5rem rgba(0, 0, 0, .3), 0 0.0625rem 0.125rem rgba(0, 0, 0, .2);
}

.chat_box::before {
	content: '';
	position: absolute;
	width: 0;
	height: 0;
	bottom: 100%;
	left: 1.5em; 
	border: .75rem solid transparent;
	border-top: none;
	border-bottom-color: #fff;
	filter: drop-shadow(0 -0.0625rem 0.0625rem rgba(0, 0, 0, .1));
}

.chat_box_user {
    position: relative;
    max-width: 45em;
    background-color: #007bff; /* Change background color to blue */
    color: #fff; /* Change text color to white */
    padding: 1.125em 1.5em;
    font-size: 1em;
    border-radius: 1rem;
    box-shadow: 0 0.125rem 0.5rem rgba(0, 0, 0, .3), 0 0.0625rem 0.125rem rgba(0, 0, 0, .2);
}

.chat_box_user::before {
    content: '';
    position: absolute;
    width: 0;
    height: 0;
    top: 100%; /* Position triangle at the bottom */
    right: 1.5em; 
    border: .75rem solid transparent;
    border-bottom: none; /* Remove top border */
    border-top-color: #007bff; /* Change border color to blue */
    filter: drop-shadow(0 0.0625rem 0.0625rem rgba(0, 0, 0, .1));
}

