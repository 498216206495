.progress {
	background-color: #DFECEC;
	border-radius: 20px;
	position: relative;
	margin: 15px 0;
	height: 30px;
	width: 100%;
}

.progress-done {
	background: linear-gradient(to left, #F18700, #FFCD59);
	box-shadow: 0 3px 3px -5px #F18700, 0 2px 5px #F18700;
	border-radius: 20px;
	color: #244042;
	display: flex;
	align-items: center;
	justify-content: center;
	height: 100%;
	width: 0;
	opacity: 0;
	transition: 1s ease 0.3s;
}