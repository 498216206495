.image-container{
    border-radius: 10px;
    overflow: hidden;
}

.image-container img{
    object-fit:cover;
    object-position:50% 60%;
    height: 100%;
    width: 100%;
}


@media screen and (max-width:600) {
    #image-box-type-1{
        width: 90%;
    }
    
}