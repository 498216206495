.contact-section{
    gap: 0;
}
.contact-section .contact-image-container{
    border-radius: 0px !important;
    overflow: hidden;
}

.contact-section .contact-image-container img{
    object-fit:cover;
    object-position:50% 60%;
    height: 100%;
    width: 100%;
}

.contact-section .form-container input,
.contact-section .form-container textarea{
    width: 70%;
    min-width: 320px;
    padding: 1rem;
    border: 1px solid black;
    font-family: 'Generica';
}

.contact-section .form-container button{
    width: 70%;
    min-width: 320px;
    padding: 1rem;
}

@media screen and (max-width:768px) {
    .contact-section h3{
        font-size: 48px;
    }

}

@media screen and (max-width:600px) {

    .contact-section {
        gap: 1rem;
    }
    .contact-section .contact-image-container{
        height: 370px;
    }
    
}