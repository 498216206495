.flip-card-section{
    overflow: hidden;
    height: 100vh;
}

.principal-section,
.secondary-section{
    border-radius: 60px 60px 0 0;
    box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
}

.principal-section{
    width: 100%;
    height: 70vh;
}
.secondary-section{
    position: relative;
    width: 100%;
    height:70vh;
    top:-10vh;
    transition: all ease-out 500ms;
}

.reflexion-box {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 1rem;
    padding: 0 2rem;
}


.reflexion-box .image-container{
    background-color: #BED8DA;
    border-radius: 50%;
    border: 5px solid white;
    padding: 3rem;

}

.progress-box{
    box-shadow: rgba(50, 50, 93, 0.75) 0px 13px 27px -5px, rgba(0, 0, 0, 0.3) 0px 8px 16px -8px;

}

.secondary-section{
    display: block;
    z-index: 10;
    overflow-y: hidden;
}

.secondary-section.expand{
    top:-60vh;
    height:100vh;
}


.secondary-section .mantra-box{
    padding-top: 1rem;
}
.secondary-section .image-container{
    height: 130px;
    width: 130px;
}

.secondary-section .next{
    background-color: #474747;
    
}

.secondary-section .next p{
    display: block;
    position: absolute;
    color: white;
    text-transform: uppercase;
    font-family: 'DIN-Condensed';
    font-weight: bold;
    font-size: 1.5rem;

}

.secondary-section .next img{
    opacity: 0.2;
}

.secondary-section .image-container img{
    object-position: center;
}


.secondary-section .mantra-box .image-container{
    box-shadow: rgba(50, 50, 93, 0.75) 0px 13px 27px -5px, rgba(0, 0, 0, 0.3) 0px 8px 16px -8px;
    transition: all 500ms ease-in-out;
    cursor: pointer;
    border-radius: 50%;
}

.secondary-section .mantra-box .image-container:hover{
    transform: scale(1.1);
}

.switch-button {
	 background: rgba(255, 255, 255, 0.56);
	 border-radius: 30px;
	 overflow: hidden;
	 width: 240px;
	 text-align: center;
	 font-size: 14px;
	 letter-spacing: 1px;
	 color: #447A7E;
	 position: relative;
	 padding-right: 120px;
	 position: relative;
     text-transform: uppercase;
}
 .switch-button:before {
	 content:"Podcast";
	 position: absolute;
	 top: 0;
	 bottom: 0;
	 right: 0;
	 width: 120px;
	 display: flex;
	 align-items: center;
	 justify-content: center;
	 z-index: 3;
	 pointer-events: none;
}
 .switch-button-checkbox {
	 cursor: pointer;
	 position: absolute;
	 top: 0;
	 left: 0;
	 bottom: 0;
	 width: 100%;
	 height: 100%;
	 opacity: 0;
	 z-index: 2;
}
 .switch-button-checkbox:checked + .switch-button-label:before {
	 transform: translateX(120px);
	 transition: transform 300ms linear;
}
 .switch-button-checkbox + .switch-button-label {
	 position: relative;
	 padding: 5px 0;
	 display: block;
	 user-select: none;
	 pointer-events: none;
}
 .switch-button-checkbox + .switch-button-label:before {
	 content: "";
	 background: #fff;
	 height: 100%;
	 width: 100%;
	 position: absolute;
	 left: 0;
	 top: 0;
	 border-radius: 30px;
	 transform: translateX(0);
	 transition: transform 300ms;
}
 .switch-button-checkbox + .switch-button-label .switch-button-label-span {
	 position: relative;
}

.secondary-section .reto-box .image-container {
    box-shadow: rgba(50, 50, 93, 0.75) 0px 13px 27px -5px, rgba(0, 0, 0, 0.3) 0px 8px 16px -8px;
    transition: all 500ms ease-in-out;
    cursor: pointer;
    border-radius: 5px;
}




@media screen and (max-width:1025px) {
   
    .flip-card-section{
        overflow: auto;
    }


    .principal-section,
    .secondary-section{
        height: fit-content;
        width: 100%;
        border-radius: 60px 60px 0 0;
    }
    .principal-section{
        padding-bottom: 3rem;
        overflow-y: auto;
    }

    .secondary-section{
        top: 0vh;
        margin-top: -10vh;

    }   

}

@media screen and (max-width:900px) {
    .index-platform{
        flex-direction:column;
        overflow-y: auto;
    }

    .flip-card-section{
        overflow: auto;
    }


    .platform-navbar{
        width: 100%;
        flex-direction: row;
        z-index: 30;
    }

    .principal-section,
    .secondary-section{
        height: fit-content;
        width: 100%;
        border-radius: 60px 60px 0 0;
    }
    .secondary-section{
        max-height: 100vh;
    }

    .principal-section{
    padding-bottom: 5rem;
}

.secondary-section{
    height: auto;
    top:0;
    margin-top: -3rem;
    overflow-y:visible;
    max-height: fit-content;
}


}

@media screen and (max-width:600px) {


.principal-section,
.secondary-section{
    height: fit-content;
    width: 100%;
    border-radius: 60px 60px 0 0;
}

.principal-section{
    padding-bottom: 5rem;
}

.secondary-section{
    height: auto;
    top:0;
    margin-top: -3rem;
    overflow-y:visible;
    max-height: fit-content;
}
    
}