.video-card-box{
    border-radius: 10px;
    box-shadow: rgba(50, 50, 93, 0.75) 0px 13px 27px -5px, rgba(0, 0, 0, 0.3) 0px 8px 16px -8px;
    overflow: hidden;
    width: 31%;
    max-width: 400px;
    min-width: 300px;
    cursor: pointer;
}

.video-card-box-2{
    border-radius: 10px;
    box-shadow: rgba(50, 50, 93, 0.75) 0px 13px 27px -5px, rgba(0, 0, 0, 0.3) 0px 8px 16px -8px;
    overflow: hidden;
    max-width: 400px;
    min-width: 300px;
    cursor: pointer;
}

.video-card-box .image-box{
    width: 100%;
    height: 120px;
    border-radius: 10px 0 10px 0;

}

.video-card-box-2 .image-box{
    width: 100%;
    height: 100px;
    border-radius: 10px 0 10px 0;

}

.video-card-box .image-box img,
.video-card-box-2 .image-box img{
    width: 100%;
    height: 100%;
    object-position: center;
    object-fit: cover;
}


.video-card-box .vertical-border{
    background-color: black !important;
}

.video-card-box .block-card{
    border-radius: 10px;
    background-color: rgba(0, 0, 0, 0.342);
    backdrop-filter: blur( 4px );
    -webkit-backdrop-filter: blur( 4px );
    width: 100%;
    height: 100%;
}

.video-card-box .image-box.is-loading,
.video-card-box.is-loading .video-spec,
.video-card-box.is-loading .sub-title{
	 background: #eee;
	 background: linear-gradient(110deg, #ececec 8%, #f5f5f5 18%, #ececec 33%);
	 border-radius: 5px;
	 background-size: 200% 100%;
	 animation: 1.5s shine linear infinite;
}

.video-card-box-2 .image-box.is-loading,
.video-card-box-2.is-loading .video-spec,
.video-card-box-2.is-loading .sub-title{
	 background: #eee;
	 background: linear-gradient(110deg, #ececec 8%, #f5f5f5 18%, #ececec 33%);
	 border-radius: 5px;
	 background-size: 200% 100%;
	 animation: 1.5s shine linear infinite;
}

.video-card-box.is-loading .sub-title{
    width: 70%;
    margin: 0.15rem;
    height: 20px;
}

.video-card-box-2.is-loading .sub-title{
    width: 180px;
    margin: 0.15rem;
    height: 20px;

}

.video-card-box.is-loading .sub-title *{
     display: none;
}

.video-card-box.is-loading .video-spec{
    margin: 0.15rem;
    width: 90%;
    height: 20px;
}

.video-card-box-2.is-loading .video-spec{
    margin: 0.15rem;
    width: 90%;
    height: 20px;
}
.video-card-box.is-loading .video-spec *,
.video-card-box-2.is-loading .video-spec *{
    display:none;
}

.video-card-box .image-box.is-loading img{
    display: none;
}

.video-card-box-2 .image-box img{
    opacity: 1;
}

.video-card-box-2 .image-box.is-loading img{
    opacity: 0;
}
 .video-card-box .image-box.is-loading {
	 border-bottom-left-radius: 0;
	 border-bottom-right-radius: 0;
}

 @keyframes shine {
	 to {
		 background-position-x: -200%;
	}
}

@media (min-width:1585px) {
     .video-card-box{   
    flex-grow: 1;
    }
    }
 

@media screen and (max-width:680px) {
    .video-card-box{
    width: 85%;    
    flex-grow: 0;
}

.video-card-box .image-box{
    width: 100%;
    height: 180px;
}
    
}