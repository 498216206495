.hero-title-about{
    position: absolute;
    display: flex;
    font-family: 'MADE-Bon-Voyage';
    top: 40%;
    left: 0%;
    max-width: 600px;;
    padding-left: 4em;
}


/*.phrase-section p::after{
    position: relative;
    display: block;
    content: "";
    margin: 42px auto 0  auto;
    width: 80%;
    background-color: rgb(54, 97, 99);
    height: 1px;
}*/


.phrase-section h2::after{
    position: relative;
    display: block;
    content: "";
    margin: 42px auto 0  auto;
    width: 90%;
    background-color: rgb(54, 97, 99);
    height: 1px;
}

.hero-image-about{
    object-position: 50% 72%;
}

.mi-bio-section .border-vertical{
        display: block;
    }


    .mi-bio-section .bg-img-right{
        right: 0%;
    }

.mi-bio-section .bg-img-right img{
    object-position: 250px 0;
}
@media screen and (max-width:600px) {

    .hero-title-about h1{
        font-size: 50px;
    }


    .hero-image-about{
        object-position: 100% 72%;
    }


    .mi-bio-section{
        overflow-x: hidden;
        max-width: 100vw;
    }
    .mi-bio-section .border-vertical{
        display: none;
    }

    .mi-bio-section .text-content{
        max-width: 100vw;
        padding: 0 0.5rem;
        overflow-x: hidden;
    }

    .mi-bio-section .bg-img{
        overflow-x: hidden;
        right: 0;
    }
    

    .hero-title-about{
        width: 300px;
        padding-left: 2rem;
    }

    .mi-bio-section .box-image-text{
        padding:1rem;

    }

    
    
}