.seriesBox{
    max-width: 320px;
}

.activeChallenge {
    color: white;
    
}

.activeChallenge p{
    line-height: 1px;
}

.classes-box{
    display: flex;
    flex-direction:column;
    gap: 0.2rem;
    overflow-y:scroll;
    -ms-overflow-style: none;  /* IE and Edge */
    scrollbar-width: none;  /* Firefox */
    height: 85%;
    max-height: 370px;
}


.classes-box::-webkit-scrollbar {
  display: none;
}

.border-1::after{
    display: block;
    content: " ";
    width: 100%;
    background-color: #EAE5C8 ;
    height: 0.5px;
}