.mySwiper{
    padding-bottom: 5rem;
}

.swiper-slide{
    display: flex;
    justify-content: center;
}

.swiper-pagination-bullet-active{
    background-color:#54989C;

}


@media screen and (max-width: 900px) {

    .mySwiper{
        width: 90%;
    }
}

@media screen and (max-width: 600px) {

    .mySwiper{
        width: 100%;
    }
}
