.grid-box{
    grid-template-rows: 450px 300px;
}

.box-image{
    object-fit:cover;
    object-position:50% 60%;
    height: 100%;
    width: 100%;
}

.box-image:first-child{
    object-position: 20% 20%;
}


@media screen and (max-width:600px){
    .grid-box{
        grid-template-columns: repeat(4,1fr);
    }
    .grid-box .box{
        grid-column: span 2;
    }
    .grid-box .box:last-child{
        grid-column: span 4;
    }
    
    .box-image{
        object-position: center;
    }
    .box-image:first-child{
    object-position: center;
    }

}