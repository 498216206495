.card-box-1{
    background: rgba(70, 70, 70, 0.285);
    color: white;
}

.card-box-1 img{
    object-fit: cover;
}

.card-box-1 p{
    font-family: 'DIN-Condensed';
    font-size: 1rem;
    line-height: 1.2rem;
    text-align: justify;
}
