.video-box{
}

.video-rating-box{
    background: rgba( 0, 0, 0, 0.5 );
    box-shadow: 0 8px 32px 0 rgba( 31, 38, 135, 0.37 );
    backdrop-filter: blur( 3.5px );
    -webkit-backdrop-filter: blur( 3.5px );
    border: 1px solid rgba( 255, 255, 255, 0.18 );

}

.video-bookmarks .image-container{
    height: 6rem;
    width: 9rem;
}

.video-bookmarks .image-container.is-loading{

	 background: #eee;
	 background: linear-gradient(110deg, #ececec 8%, #f5f5f5 18%, #ececec 33%);
	 border-radius: 5px;
	 background-size: 200% 100%;
	 animation: 1.5s shine linear infinite;
}

.video-bookmarks .image-container.is-loading img{
    display: none;
}
 .video-bookmarks .image-container .is-loading {
	 border-bottom-left-radius: 0;
	 border-bottom-right-radius: 0;
}

.arrow-back-player{
    position: absolute;
    top: 20px;
    left: 200px;
}
.css-1c99szj-MuiRating-icon{
    color: white !important;
}

 @keyframes shine {
	 to {
		 background-position-x: -200%;
	}
}


@media screen and (max-width:1025px) {
    .arrow-back-player{
        left: 150px;
    }
}



@media screen and (max-width:768px) {
    .arrow-back-player{
        top: 90px;
        left: 20px;
    }

    .video-box{
        flex-direction: column;
    }

}

@media screen and (max-width:600px) {
    .video-box{
        flex-wrap: wrap;
        padding: 1rem;
    }

    .video-bookmarks{
        width: 90%;
        flex-direction: row;
        gap: 2;
    }
    
    

    
}