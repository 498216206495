.hero-promo{
    height: 500px;
    width: 100vw;
    background: rgb(234,229,200);
    background: linear-gradient(135deg, rgba(234,229,200,1) 0%, rgba(234,229,200,0.95) 39%, rgba(234,229,200,0) 100%);
    background-image:  linear-gradient(135deg, rgba(234,229,200,1) 0%, rgba(234,229,200,0.95) 39%, rgba(234,229,200,0.29) 100%),url("../../assets/images/sliders/Sara-P-12.jpg");
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center;
}

.promo-img{
    object-position: 50% 35% !important;
}


@media screen and (max-width: 1100px) {
    .hero-promo {
        height: auto;
        max-width: 100vw;
    }
}