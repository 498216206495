.platform-navbar .menu-platform{
    position: relative;
}

.platform-navbar .menu-platform .icon-img{
    filter: brightness(0) saturate(100%) invert(94%) sepia(9%) saturate(507%) hue-rotate(10deg) brightness(98%) contrast(90%);
}

.platform-navbar .menu-platform .menu-item-p{
    background: linear-gradient(to right, rgba( 249, 174, 0, 0.75 ) 0%, rgba( 249, 174, 0, 0.75 ) 0%) no-repeat;
    background-size: 0% 100%;
    border-radius: 10px;
    transition: background-size 0.6s cubic-bezier(0.25, 0.46, 0.45, 0.94);
    padding-top: 0.75rem;
    padding-bottom: 0.75rem;
    color:'#EAE5C8';

}

.platform-navbar .menu-platform .menu-item-p:hover,
.platform-navbar .menu-platform .menu-item-p.active{
    box-shadow: 0 8px 32px 0 rgba( 31, 38, 135, 0.37 );
    backdrop-filter: blur( 4px );
    -webkit-backdrop-filter: blur( 4px );
    transform: scale(1.1);
    border-radius: 0 10px 10px 0;
    background-size: 100% 100%;
}


.platform-navbar .menu-platform .menu-item-p:hover img,
.platform-navbar .menu-platform .menu-item-p.active img{
 filter: brightness(0) saturate(100%) invert(19%) sepia(54%) saturate(345%) hue-rotate(135deg) brightness(93%) contrast(90%);
}

.platform-navbar .avatar-info{
    padding-bottom: 2.5rem;

}




@media screen and (max-width:900px) {
    .platform-navbar{
        height: fit-content;
        padding: 1rem;
    }

    .platform-navbar.stickNav{
        position: fixed;
    }

    .platform-navbar .menu-platform{
        flex-direction: row;
        gap: 1.25rem;
        padding-right: 2rem;
        padding-left: 2rem;
    }

    .platform-navbar .menu-platform .menu-item-p{
        padding-left: 0.25rem;
    }

    .platform-navbar .menu-platform .menu-item-p:hover,
    .platform-navbar .menu-platform .menu-item-p.active{

    transform: scale(1);
    }

    .platform-navbar .avatar-info{
    padding-bottom: 0;
    }

}

@media screen and (max-width:640px) {
     .platform-navbar .menu-platform{
        position: absolute;
        top:0;
        right:0;
        left: 100vw;
        z-index: 10;
        display: flex;
        flex-direction: column;
        height: 100vh;
        width: 90vw;
        background: rgba( 54, 97, 99, 0.7 );
        box-shadow: 0 8px 32px 0 rgba( 31, 38, 135, 0.37 );
        backdrop-filter: blur( 7.5px );
        -webkit-backdrop-filter: blur( 7.5px );
        border-radius: 10px;
        border: 1px solid rgba( 255, 255, 255, 0.18 );
        justify-content: center;
        align-items: center;
        transition: left 0.6s cubic-bezier(0.25, 0.46, 0.45, 0.94);
    }


    .platform-navbar .menu-platform.active{
        left: 10vw;
    }

}