.card-box-3{
    background: rgba(70, 70, 70, 0.285);
    color: white;
}

.card-box-3 img{
    object-fit: cover;
}

.card-box-3 p{
    font-family: 'DIN-Condensed';
    font-size: 1rem;
    line-height: 1.2rem;
    text-align: justify;
}

.card-box-3.next{
    background-color: #141414a3;
    
}

.card-box-3.next p{
    display: block;
    position: absolute;
    color: white;
    text-transform: uppercase;
    font-family: 'DIN-Condensed';
    font-weight: bold;
    font-size: 1.5rem;

}

