.register-section-1{
    background:linear-gradient(0deg, rgba(36,64,66,0.3) 10%, rgba(255,255,255,0) 100%), url('../../../assets/images/sliders/sara-4.png');
    background-position: center;
    background-size: cover  ;
    height: 100vh;
    width: 100vw;
    display: flex;
    flex-wrap: wrap;
    align-items: center;
}

.card-register{
   background: rgba( 255, 255, 255, 0.75 );
    box-shadow: 0 8px 32px 0 rgba( 31, 38, 135, 0.37 );
    backdrop-filter: blur( 3.5px );
    -webkit-backdrop-filter: blur( 3.5px );
    border-radius: 10px;
    border: 1px solid rgba( 255, 255, 255, 0.18 );
    }