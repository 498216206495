.button-2{
    border-radius: 60px;
    width: 110px;
    text-align: center;
    height: fit-content;
    padding: 0.4rem 0;
    font-size: 0.85rem;
    border: 1px solid #EAE5C8;
    transition: all 500ms ease-in-out;
    color: #EAE5C8;
}

.active,
.button-2:hover{
    background-color: #ffffff ;
    color: #244042;
}

.button-2:hover #c-box-img{
filter: brightness(0) saturate(100%) invert(19%) sepia(54%) saturate(345%) hue-rotate(135deg) brightness(93%) contrast(90%);
}


.desactive{
    background-color: #ffffff00;
    color: #EAE5C8;
}

.vertical-border{
    display: block;
    content: " ";
    width: 1px;
    background-color: #ffffff ;
    height: auto;
}

.card-box p{
    line-height: 20px;
    color: white;
}


.card-box .video-spec.is-loading,
.card-box p.is-loading {
	 background: #eee;
	 background: linear-gradient(110deg, #bbbaba 8%, #908f8f 18%, #ececec 33%);
	 border-radius: 5px;
	 background-size: 200% 100%;
	 animation: 1.5s shine linear infinite;
}

.card-box .video-spec.is-loading {
    width: 80%;
    margin: 0.15rem;
    height: 15px;
}

.card-box p.is-loading{
    color:#f3f3f300
}

.card-box p.is-loading,
.card-box .video-spec.is-loading *{
     display: none;
}

#c-box-img{
    filter: brightness(0) saturate(100%) invert(94%) sepia(9%) saturate(507%) hue-rotate(10deg) brightness(98%) contrast(90%);
}

 @keyframes shine {
	 to {
		 background-position-x: -200%;
	}
}