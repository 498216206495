.menu-item{
    font-family: 'DIN-Condensed';
    transition: all 500ms ease-in-out;
    color: black;
}

.menu-item:hover{
    text-decoration: underline;
    transform: scale(1.1);
}

.navbar-box{
    background-color: white;
    transition: all 500ms ease-in-out;
    opacity: 0.3;
}

.navbar-box:hover{
    opacity: 1;
}

#social-icons{
    display: flex;
    flex-direction: row;
    gap:0.75rem;
}

nav #social-icons .icon-img{
    height: 32px;
    
}

nav #social-icons .icon-img img{
    object-fit: contain;
    height: 100%;
    width: 100%;

}

 nav .menu-items-box{
        display: flex;
        position: relative;
        height: auto;
        flex-direction: row;

    }

nav .menu-items-box .close-icon{
    display: none ;
}



nav .burger-menu{
    display: none;
}

@media screen and (max-width:600px) {

    nav .burger-menu{
        display: block;
    }
    nav .menu-items-box{
        display: none;
        flex-direction: column;
        font-size: 24px;
        position: absolute;
        height: 100vh;
        width: 0vw;
        background-color: aliceblue;
        z-index: 1;
        inset: 0 0 0 0;
        transition: all 1s ease-in-out;
        -webkit-transition: all 1s ease-in-out; 
        animation: all 2s ease;
        padding-bottom: 50px;
    }

    nav .menu-items-box .menu-item {
        text-transform: uppercase;
        font-family: 'DIN-Condensed';
        font-weight: bold;
        font-size: 36px;
    
    }

    nav .menu-items-box .close-icon{
        display: block;
        position: absolute;
        top: 20px;
        right: 20px;
         transform: scale(1.8);;
    }

    nav .menu-active{
        display: flex;
        width: 100vw;
        padding: 0;
    }

    nav .social-icons{
        display: none;
        z-index: 10;
    }

    #social-icons{
    gap:0.5rem;
    }
}