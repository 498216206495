.button-box-3{
  align-items: baseline;
}

.button-box-3 h6{
  max-width: 100px;
}


.button-3  {
  position: relative;
  padding: 13px 0px;
  color: #ffffff;
  border: none;
  height: 110px;
  width: 110px;
  border-radius: 50%;
  -webkit-transition: all .3s ease-in-out;
  transition: all .3s ease-in-out;
  background: #EAE5C8;
  font-family: MADEBonVoyage;
  font-size: 60px;
  color: #447A7E;
  text-align: center;
}

.icon-1,
.icon-2,
.icon-3,
.icon-4,
.icon-5 {
  position: absolute;
  top:10%;
  left: 0%;
  opacity: 1;
  width: 0;
  height: auto;
  -webkit-transition: all 0.75s ease-in-out;
  -moz-transition: all 0.75s ease-in-out;
  -ms-transition: all 0.75s ease-in-out;
  -o-transition: all 0.75s ease-in-out;
  transition: all 0.75s ease-in-out;
  z-index: -1;
}

.icon-1 img,
.icon-2 img{
  width: 100%;
}

.button-3 :hover .icon-1{
    top: -110%;
    left: 0%;
    width: 150px;
}


.button-3 :hover .icon-2{
    top: -120%;
    left: -10%;
    width: 130px;
}

.button-3 :hover .icon-3{
    top: -160%;
    left: -25%;
    width: 170px;
}

.button-3 :hover .icon-4{
    top: -120%;
    left: -10%;
    width: 130px;
}

.button-3 :hover .icon-5{
    top: -90%;
    left: -10%;
    width: 130px;
}


@media screen and (max-width:600px) {

  .button-3  {
  height: 80px;
  width: 80px;
  font-size: 42px;
  }

.icon-1 img,
.icon-2 img{
  width: 100%;
}

.button-3 :hover .icon-1{
    top: -110%;
    left: 0%;
    width: 80px;
}


.button-3 :hover .icon-2{
    top: -90%;
    left: -5%;
    width: 70px;
}

.button-3 :hover .icon-3{
    top: -125%;
    left: -15%;
    width: 100px;
}

.button-3 :hover .icon-4{
    top: -100%;
    left: -5%;
    width: 80px;
}

.button-3 :hover .icon-5{
    top: -100%;
    left: -5%;
    width: 90px;
}
  
}