.loader{
display: flex;
justify-content: center;
align-content: center;
  height: 100%;
  width: 100%;
  text-align: center;
  vertical-align: top;
}

/*
  Set the color of the icon
*/
.loader svg path,
.loader svg rect{
  fill: #FF6700;
}