.library-section {
display:flex;
flex-wrap: wrap;
justify-content: space-between;
gap: 1.25rem;
}

.library-section::after {
content: "";
flex-basis: 31% /* give it the same width of your child or item; */
}

.button-31:hover {
  box-shadow: rgba(45, 35, 66, .4) 0 4px 8px, rgba(45, 35, 66, .3) 0 7px 13px -3px, #DFECEC 0 -3px 0 inset;
  -webkit-transform: translateY(-2px);
          transform: translateY(-2px);
}

.button-31:active {
  box-shadow: #DFECEC 0 3px 7px inset;
  -webkit-transform: translateY(2px);
          transform: translateY(2px);
}


.filter-by-mandala-box .mantra-box{
    padding-top: 2rem;
}

.filter-by-mandala-box .next{
    background-color: #474747;
    
}

.filter-by-mandala-box .next p{
    display: block;
    position: absolute;
    color: white;
    text-transform: uppercase;
    font-family: 'DIN-Condensed';
    font-weight: bold;
    font-size: 1rem;

}

.filter-by-mandala-box .next img{
    opacity: 0.2;
}

.filter-by-mandala-box .image-container img{
    object-position: center;
}


.filter-by-mandala-box .mantra-box .image-container{
    box-shadow: rgba(50, 50, 93, 0.75) 0px 13px 27px -5px, rgba(0, 0, 0, 0.3) 0px 8px 16px -8px;
    transition: all 500ms ease-in-out;
    cursor: pointer;
    border-radius: 50%;
}

.filter-by-mandala-box .mantra-box .image-container:hover{
    transform: scale(1.1);
}

.intensity-card-box{
    border-radius: 10px;
    box-shadow: rgba(50, 50, 93, 0.75) 0px 13px 27px -5px, rgba(0, 0, 0, 0.3) 0px 8px 16px -8px;
    overflow: hidden;
    padding: 1.5rem;
    max-width: 400px;
    min-width: 300px;
    cursor: pointer;
}


@media (min-width:1585px) {
    .library-section {
        justify-content: flex-start;
        gap: 1.25rem;
    }
    .library-section::after {
    content: "";
    flex-basis: 0 /* give it the same width of your child or item; */
    }       
}

    
@media screen and (max-width:680px) {
.library-section {
    display:flex;
    flex-wrap: wrap;
    justify-content: center;
    gap: 1.25rem;
}
.intensity-card-box{
    padding: 1rem;
}

    
}