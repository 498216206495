footer{
    background-color: rgb(25, 25, 25);
}

footer img{
    filter: brightness(0) saturate(100%) invert(49%) sepia(27%) saturate(566%) hue-rotate(136deg) brightness(83%) contrast(92%);
}

footer .border{
    display: block;
    content: "";
    height: 1px;
    color:  #447A7E;
}