.reset-section{
    background:linear-gradient(0deg, rgba(36, 64, 66, 0.5) 70%, rgba(255,255,255,0) 100%), url('../../assets/images/sliders/Sara-P-3.jpg');
    background-position: center;
    background-size: cover  ;
    height: 100vh;
    width: 100vw;
    display: flex;
    flex-wrap: wrap;
    justify-content:space-between;
    align-items: center;
}

.reset-section .text-box{
    display: flex;
    height: 100%;
    justify-content: flex-start;
    align-items: center;
    padding-top: 10%;
}

.reset-section .form{
    display: grid;
    align-items: center;
    justify-content: center;

}

.reset-section .form-box{
    width: 30vw;
    min-width: 300px;
    padding: 2rem;
    border-radius: 10px;
    display: flex;
    background: rgba(255, 255, 255, 0.866);
box-shadow: 0 8px 32px 0 rgba( 31, 38, 135, 0.37 );
backdrop-filter: blur( 3.5px );
-webkit-backdrop-filter: blur( 3.5px );
border-radius: 10px;
}
.reset-section .form-box input{
    padding: 1rem;
    width: 100%;
}

@media screen and (max-width: 600px) {
    .reset-section {
        background:linear-gradient(0deg, rgba(36, 64, 66, 0.5) 70%, rgba(255,255,255,0) 100%), url('../../assets/images/sliders/sara-11.webp');
        background-position: center;
        flex-direction: column;
        justify-content: center;
        gap: 1rem;
        
    }

    .reset-section .text-box{
        height: auto;
        padding: 0.5rem;
    }

    .reset-section .form-box{
    width: 90vw;
    min-width: 280px;
    }

    
}