body{
    max-width: 100vw;
    overflow-x: hidden;

}
/*----------HERO SECTION START-----------*/


.hero-section{
    height: 100vh;
    min-height: 690px;
}

.slider{
    overflow: hidden;
}

.hero-image{
    object-fit:cover;
    object-position:50% 60%;
    height: 100%;
    width: 100%;
}

.hero-title{
    position: absolute;
    display: flex;
    font-family: 'MADE-Bon-Voyage';
    top: 50%;
    left: 50%;
    translate: -50% -50%;
}

.hero-title .title-1{
    width: 100%;
    justify-content: center;
}

.hero-title .sub-title{
    text-align: center;
}

.button-box{
    position: absolute;
    bottom: 10%;
    left: 50%;
    translate: -50% -50%;
}

/*----------HERO SECTION END--------------*/

/*----------ABOUT SECTION START-----------*/

.about-section{
    background: linear-gradient(180deg, #61A0A4 0%, #FFFFFF 100%),
     radial-gradient(circle at 50% 45%, #61A0A4 0%, #697D7E 20%, #FFFFFF 69%);
     padding-bottom: 5rem;
}

.about-section .content-box{
    display: grid;
    grid-template-columns: repeat(12,1fr);
    grid-template-rows: min-content;
}

.about-section .content-box .content{
        grid-column: span 4;
}

.about-section .content-box .content p{
    font-family: 'MADE-Bon-Voyage';
    font-size: 1.1rem;
    line-height: 1.2rem;
    font-weight: bold;
}

.about-section .content-box .content:first-child{
    grid-row-start: 1;
    grid-column: 4 / 10;
}
.about-section .content-box .content:nth-child(2){
    grid-row-start: 2;
     grid-column: span 4;
}

.about-section .content-box .image-box{
     grid-column: span 4;
    grid-row-start: 2;
}

.about-section .content-box .content:nth-child(5){
    grid-row-start: 2;
    grid-column: 9/13;
}

.about-section .content-box .content:nth-child(3){
    grid-row-start: 4;
    grid-column: 1/6;
}


.about-section .content-box .content:last-child{
    grid-row-start: 4;
    grid-column: 7/13;
}

.about-section .icon-container{
    cursor: default;
}

.about-section .content-box p{
    display: block;
}

.content{
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 0.5rem;
  gap:1rem;
  font-family:'MADE-Bon-Voyage';
  text-align: center;
  z-index: 2;
}



.image-box img{
    object-fit:contain;
    object-position:50% 60%;
    height: 100%;
    width: 100%;
    overflow: auto;
}

.about-section .image-box img{
    transform: scale(1.5);
}

#sara-p1{
    transform: scale(1.5);
}

.pulse {
  animation: pulse 2s infinite ease-in-out alternate;
}
@keyframes pulse {
  from { transform: scale(0.8); }
  to { transform: scale(1.2); }
}

.bounce {
  animation: bounce 2s ease infinite;
}
@keyframes bounce {
    70% { transform:translateY(0%); }
    80% { transform:translateY(-15%); }
    90% { transform:translateY(0%); }
    95% { transform:translateY(-7%); }
    97% { transform:translateY(0%); }
    99% { transform:translateY(-3%); }
    100% { transform:translateY(0); }
}

/*----------ABOUT SECTION END------------*/

/*----------BENEFITS SECTION START-----------*/
.benefits-section .steps-content{
    padding-top: 10rem;
    gap:0;
    align-items: baseline;
}

/*----------BENEFITS SECTION END-----------*/


/*----------CARDS SECTION START-----------*/

.left-content{
    position: relative;
    z-index: 1;
    display: flex;
    justify-content: center;
    align-items: center;
    min-height: 600px;
}

.left-content::before{
    content: "";
    position: absolute;
    top: 0; 
    left: -200px;
    width: 100%; 
    height: 100%;  
    opacity: .05; 
    z-index: -1;
    background-image: url("../../assets/icons/Mandala/mandala-8.png");
    background-repeat: no-repeat;
}

.left-content h4,
.left-content h6{
    text-align: center;
    padding: 1.5rem 0;
}


.left-content h4::before,
.left-content h6::after{
    display: block;
    content: "";
    margin: 50px auto;
    height: 1.5px;
    width:600px;
    background-color: #244042;
}

/*----------CARDS SECTION END-----------*/

/*----------SOCIAL SECTION START-----------*/

.social-media-section .border{
    display: block;
    content: "";
    width: 1px;
    height: 300px;
    color:  rgb(234 229 200);
}

@media screen and (max-width:768px) {
    .about-section .content-box p{
        display: none;
    }


}




@media screen and (max-width:600px) {

    h1{
    font-size: 52px;
    line-height: 52px;
    }

    h2{
        font-size: 48px;
        line-height: 48px;
    }

    h3{
    font-size: 40px;
    line-height: 40px;
    }

    h4{
    font-size: 36px;
    line-height: 48px;
    }

    h5{
        font-size: 24px;
        line-height: 24px;
    }

    h6{
    font-size: 20px;
    line-height: 20px;
    }
        
    .social-media-section .border{
    display: block;
    content: "";
    width: 90%;
    height: 2px;
    color:  rgb(234 229 200);
    }

    .about-section .content-box{
        grid-template-columns: repeat(6,1fr);
        grid-template-rows: 1fr 375px 1fr;
        align-self: center;
        gap: 2rem 0;
    }

    .about-section .content-box .content .icon-container{
        justify-content: center;
    }

    .about-section .content-box .content:first-child{
        grid-row-start: 1;
        grid-column: span 2;
    }
    .about-section .content-box .content:nth-child(2){
        grid-row-start: 1;
        grid-column: span 2;
    }

    .about-section .content-box .content:nth-child(5){
        grid-row-start: 1;
        grid-column: span 2;
    }

    .about-section .content-box .content:nth-child(3){
        grid-row-start: 3;
        grid-column: span 3;
    }

    
    .about-section .content-box .content:last-child{
        grid-row-start: 3;
        grid-column: span 3;
    }
    .about-section .content-box .image-box{
        grid-column: span 6;
        grid-row-start: 2;
    }

    .about-section .content-box .image-box{
        overflow: hidden;
    }

    .about-section .image-box img {
        transform: scale(1.2);
        object-fit: cover;
    }

    .left-content{
        min-height: auto;
    }
    

    .left-content .text::before,
    .left-content .text::after{
        width: 100%;
    }

    .left-content::before{
        left: 15%;
        background-size: 400px;
    }
    

    .right-content{
        padding-left: 30px;
    }

    .training-section{
        padding-bottom: 60px;
    }

    .benefits-section  .steps-content{
        padding: 6rem 1rem 0 1rem;
        gap: 7rem 2rem;
    }

    .left-content h4::before,
    .left-content h6::after{
        display: block;
        content: "";
        margin: 50px auto;
        height: 1.5px;
        width:300px;
        background-color: #244042;
    }
}

@media screen and (max-width:400px) {

    .about-section .content-box{
        grid-template-rows: 1fr 300px 1fr;
    }

}